import React, {useContext} from 'react';
import Image from 'gatsby-image';
import {useHeaderImage} from '../shared/Hooks/Headers';
import {useTextImage} from '../shared/Hooks/HowWeWork/useTextImage';
import {useProcessTimelineImage} from '../shared/Hooks/HowWeWork/useProcessTimelineImage';
import {ThemeContext} from 'styled-components';
import {Paragraph, NormalHeader} from '../components/Typography';
import {StyledImage} from '../sections/Case/CaseCard/styles';
import {First, Second, Fifth, Fourth, Third} from '../components/Icons/Timeline';
import {GreenText} from '../sections/HowWeWork/BottomComponent/styles';
import {useBackgroundImage} from '../shared/Hooks/Background';

export const useData = () => {
  const headers = useHeaderImage();
  const textImages = useTextImage();
  const processImages = useProcessTimelineImage();
  const themeContext = useContext(ThemeContext);
  const backgroundImgs = useBackgroundImage();

  return {
    seo:{
      title: 'Jak pracujemy',
    },
    header: {
      image:
        <Image fluid={headers.howWeWorkHeader.childImageSharp.fluid}/>,
      section: 'Jak pracujemy',
      title: <>Nowatorskie koncepcje i&nbsp;możliwości</>,
    },
    textSection: {
      images: [
        <Image
          objectFit="cover"
          fluid={textImages.image_1.childImageSharp.fluid}
        />,
        <Image
          objectFit="cover"
          fluid={textImages.image_2.childImageSharp.fluid}
        />,
      ],
      text_1: (
        <>
          W cykl pracy nad projektem zaangażowanych jest kilka działów budujących
          wokół niego kompletny ekosystem usług. Projektanci, konstruktorzy,
          logistycy i&nbsp;montażyści - każdy z zespołów aktywnie
          i&nbsp;merytorycznie współpracuje nad produktem, pod opieką działu obsługi
          klienta koordynującego współpracę. Dzięki wielopoziomowej kooperacji
          oferujemy zawsze przemyślany pod względem wizualnym, praktycznym
          i&nbsp;użytkowym produkt, dopasowany do oczekiwań i specyfikacji klienta.
        </>
      ),
      text_2: (
        <>
          Stosujemy zróżnicowane technologie produkcyjne, pozwalające na wykonywanie
          materiałów POS z&nbsp;płyty meblowej, sklejki, tworzywa (HIPS, PS, PMMA),
          metalu oraz materiałów kompozytowych (dibond).
        </>
      ),
      text_3: (
        <>
          We wszystkich działach zatrudniamy specjalistów doskonale znających swój
          fach i&nbsp;co równie ważne, podchodzących do niego z pasją.
        </>
      ),
    },
    processSection: {
      title: <>Jak powstają <br></br> nasze realizacje?</>,
      parallaxText: (
        <Image fixed={backgroundImgs.process.childImageSharp.fixed}/>
      ),
      data: [
        {
          id: '01',
          title: (
            <NormalHeader bolded color={themeContext.color.green}>
              Zdefiniowanie potrzeb i burza mózgów
            </NormalHeader>
          ),
          description: (
            <Paragraph>
              Prowadzimy rozmowy dokładnie wsłuchując się w&nbsp;wymagania klienta
              i&nbsp;określając sposób ich spełnienia. Dzielimy się w prowadzonych
              konsultacjach spostrzeżeniami i&nbsp;sugestiami opartymi o posiadaną
              wiedzę i doświadczenie.
            </Paragraph>
          ),
          icon:
            <First height={'64px'} color={themeContext.color.gray}/>,
          img: (
            <StyledImage fluid={processImages.brainstorm.childImageSharp.fluid} id={'01'}/>
          ),
        },
        {
          id: '02',
          title: (
            <NormalHeader bolded color={themeContext.color.green}>
              Wizualizacja i projekt
            </NormalHeader>
          ),
          description: (
            <Paragraph>
              Nasze materiały powstają dzięki ścisłej współpracy konstruktorów i
              projektantów, gwarantując optymalne zbalansowanie pomiędzy
              technicznymi i&nbsp;estetycznymi aspektami oferowanego rozwiązania.
            </Paragraph>
          ),
          icon:
            <Second height={'64px'} color={themeContext.color.gray}/>,
          img: (
            <StyledImage
              fluid={processImages.visualisation.childImageSharp.fluid}
              id={'01'}
            />
          ),
        },
        {
          id: '03',
          title: (
            <NormalHeader bolded color={themeContext.color.green}>
              Opracowanie techniczne
            </NormalHeader>
          ),
          description: (
            <Paragraph>
              Po etapie akceptacji opracowujemy dokładny model cyfrowy projektu
              uwzględniający materiały oraz kwestie logistyczne związane z
              magazynowaniem i&nbsp;transportem.
            </Paragraph>
          ),
          icon:
            <Third height={'64px'} color={themeContext.color.gray}/>,
          img: (
            <StyledImage fluid={processImages.development.childImageSharp.fluid} id={'01'}/>
          ),
        },
        {
          id: '04',
          title: (
            <NormalHeader bolded color={themeContext.color.green}>
              Prototyp i produkcja
            </NormalHeader>
          ),
          description: (
            <Paragraph>
              Przed produkcją seryjną prezentujemy klientowi wzorzec, na bazie
              którego akceptowane są przedstawione rozwiązania.
            </Paragraph>
          ),
          icon:
            <Fourth height={'64px'} color={themeContext.color.gray}/>,
          img: (
            <StyledImage fluid={processImages.production.childImageSharp.fluid} id={'01'}/>
          ),
        },
        {
          id: '05',
          title: (
            <NormalHeader bolded color={themeContext.color.green}>
              Wdrożenie
            </NormalHeader>
          ),
          description: (
            <Paragraph>
              Do realizowanych projektów oferujemy komplet usług z&nbsp;zakresu
              logistyki. Magazynowanie, transport, instalację w punkcie handlowym i
              gwarancyjną oraz pogwarancyjną opiekę nad produktem.
            </Paragraph>
          ),
          icon:
            <Fifth height={'64px'} color={themeContext.color.gray}/>,
          img: (
            <StyledImage fluid={processImages.deployment.childImageSharp.fluid} id={'01'}/>
          ),
        },
      ],
    },
    departments: {
      title: 'Nasze działy',
      data: [
        {
          title: 'Dział projektowy',
          text:
            'Dział obsługi klienta, graficzny i konstrukcyjny, współpracują przy opracowaniu każdego nowego projektu. Dzięki temu oferujemy zawsze przemyślany pod względem wizualnym, praktycznym i użytkowym produkt dopasowany do oczekiwań i specyfikacji klienta.',
        },
        {
          title: 'Dział konstrukcyjny',
          text:
            'Podążamy za trendami rynkowymi korzystając ze sprawdzonych rozwiązań oraz opracowanych we własnym zakresie patentów. Wybieramy najbardziej optymalne pod względem ergonomii i praktyczności rozwiązania.',
        },
        {
          title: 'Dział prototypowania i produkcji',
          text:
            'Posiadamy park maszynowy i doświadczenie pozwalające na produkcję ekspozytorów z drewna, metalu i tworzywa pod jednym dachem. Przed rozpoczęciem każdej produkcji seryjnej wykonujemy wzorce ukazujące docelowy wyglądi funkcjonalność. Produkujemy też wzorniki, dostarczając kompletny zestaw do punktu handlowego i zapewniając całkowity cykl produkcji wraz z dystrybucją.',
        },
        {
          title: 'Dział logistyki',
          text: (
            <>
              W zależności od specyfiki stojaka optymalizujemy konstrukcję i sposób
              pakowania już na etapie projektu. Nasze materiały przygotowujemy m.in.
              pod kątem transportu TIR, własnych samochodów dostawczych lub dostawców
              zewnętrznych klienta. Działamy w całej Europie. <br/>
              <br/>W ramach usług logistycznych wykonujemy nie tylko montaże
              stojaków, ale też kompleksowe instalacje stref sprzedaży (shop in shop)
              w punktach. Projektujemy układ dopasowując się do wnętrz sklepów i
              adaptując do już istniejących rozwiązań.
            </>
          ),
        },
      ],
    },
    bottomComponent: {
      text: <>
        <GreenText>W ramach usług logistycznych</GreenText> wykonujemy nie
        tylko montaże stojaków ale też{" "}
        <GreenText>kompleksowe instalacje stref sprzedaży</GreenText> (shop in
        shop) w punktach. Projektujemy układ dopasowując się do wnętrz sklepów
        i&nbsp;adaptując do już istniejących rozwiązań.
      </>
    }
  };
};

import React from "react"
import HowWeWork from '../sections/HowWeWork';
import {useData} from '../data/how-we-work';

const HowDoWeWork = () => {
  const data = useData();

  return (
   <HowWeWork data={data}/>
  )
}

export default HowDoWeWork
